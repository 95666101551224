.wrapper {
  background-color: #fff;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media print {
    padding: 16px 8px;
    background-color: var(--grey100);
  }
  @media screen and (min-width: 1024px) {
    flex-direction: row;
    padding: 16px 24px;
  }
}

.left,
.right {
  display: flex;
  align-items: center;
  width: 100%;
  @media screen and (min-width: 1024px) {
    width: auto;
  }
}

.left {
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
  margin-bottom: 8px;
  @media screen and (min-width: 1024px) {
    align-items: center;
    flex-direction: row;
    margin-bottom: 0;
    width: 60%;
    gap: 8px;
  }
}

.right {
  display: flex;
  justify-content: flex-start;
  column-gap: 16px;
  @media screen and (min-width: 768px) {
    justify-content: space-around;
    margin-bottom: 0;
    width: 40%;
    gap: 16px;
  }
}

.person {
  display: flex;
  align-items: center;
  gap: 16px;
  @media print {
    gap: 8px;
  }
  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #000;
  }
}

.personImage {
  background-color: var(--grey300);
  width: 36px;
  height: 36px;
  border-radius: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  svg {
    font-size: 18px;
  }
}

.info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  @media screen and (min-width: 1200px) {
    flex-wrap: nowrap;
    margin-left: 32px;
  }
}

.tags {
  display: flex;
  align-items: center;
  gap: 6px;
}

.infoText {
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  white-space: nowrap;
  @media screen and (min-width: 768px) {
    font-size: 14px;
  }
}

.subTask {
  color: var(--grey500);
  font-size: 13px;
  font-weight: 700;
  white-space: nowrap;
}

.date {
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  color: var(--grey600);
  display: flex;
  align-items: center;
  @media print {
    justify-content: flex-end;
  }
  @media screen and (min-width: 768px) {
    font-size: 14px;
  }
}

.hours {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  svg {
    min-width: 18px;
    font-size: 18px;
    color: var(--grey600);
  }
  @media print {
    display: none;
  }
  @media screen and (min-width: 768px) {
    font-size: 14px;
  }
}

.total {
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  @media screen and (min-width: 768px) {
    font-size: 14px;
  }
}
