.container {
  padding-bottom: 64px;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    width: 64px;
    height: 64px;
  }
}

.header {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.headerLeft {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;

  @media screen and (min-width: 1024px) {
    max-width: 55%;
    align-items: center;
  }
  @media screen and (min-width: 1270px) {
    max-width: 65%;
    align-items: center;
  }
}

.back {
  padding: 12px;
  border-radius: 100px;
  background-color: #000;
  display: flex;
  width: fit-content;
  margin-right: 24px;
  position: relative;
  z-index: 1;
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100px;
    transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    background-color: #000;
    z-index: -1;
  }
  &:hover {
    &::after {
      transform: scale(1.1);
    }
  }
  svg {
    font-size: 18px;
    color: #fff;
  }
}

.titleContainer {
  margin-bottom: -24px;
  max-width: 80%;
  @media screen and (min-width: 1200px) {
    width: 80%;
  }

  .title {
    h1 {
      font-weight: 700;
      line-height: 42px;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 24px;
      @media screen and (min-width: 1024px) {
        font-size: 32px;
      }
    }
  }

  p {
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
    color: #606060;
  }
}

.buttons {
  display: flex;
  gap: 16px;
  position: relative;
  margin-top: 24px;
  flex-direction: column;

  button {
    white-space: nowrap;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 414px) {
      width: calc(50% - 8px);
    }
  }
  @media screen and (min-width: 414px) {
    flex-direction: row;
  }
  @media screen and (min-width: 1024px) {
    white-space: inherit;
    margin-left: auto;
    margin-top: 0;
    align-items: center;
  }
}

.missions {
  margin-bottom: 64px;
}

.missionsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  .missionsHeaderTitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 0;
    color: #000;
    @media screen and (min-width: 768px) {
      font-size: 22px;
    }
  }
}

.time {
  margin-bottom: 56px;
}

.timeTitle {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 24px;
  @media screen and (min-width: 768px) {
    font-size: 22px;
  }
}

.timeNumbers {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    color: #000;
    @media screen and (min-width: 768px) {
      font-size: 14px;
    }
  }
}

.noMission {
  color: #646464;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 96px;
}

.missionsList {
  display: flex;
  margin: 0 -12px;
  flex-wrap: wrap;
  .missionsItem {
    width: 100%;
    padding: 0 12px;
    margin-bottom: 24px;
    @media screen and (min-width: 768px) {
      width: 50%;
    }
    @media screen and (min-width: 1024px) {
      width: calc(100% / 3);
    }
  }
}

.red {
  background-color: var(--red);
  border-radius: 100px;
  padding: 4px 8px;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  margin-left: 8px;
}

.title {
  display: flex;
  align-items: center;
}

.inactive {
  margin-left: 12px;
  display: inline-flex;
  align-items: center;
  padding: 8px 12px;
  color: #000;
  font-size: 12px;
  font-weight: 700;
  background-color: var(--grey300);
  border-radius: 100px;
  height: 32px;
  svg {
    margin-right: 8px;
    fill: #000;
    width: 16px;
    height: 16px;
  }
}
