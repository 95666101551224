.container {
  margin-bottom: 72px;
  position: relative;
  min-height: calc(100vh - 156px);
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 24px;
}

.input {
  margin-right: 10px;
  width: 100%;
  max-width: 289px;

  @media screen and (min-width: 768px) {
    margin-right: 0;
  }
}

.sort {
  position: relative;
  z-index: 11;
  display: flex;
  column-gap: 16px;

  span {
    display: none; 
    @media screen and (min-width: 1024px) {
      display: block;
    }
  }
}

.filter {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  width: 221px;
}

.dropdownItem {
  display: flex;
  align-items: center;
  padding: 10px 8px;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  gap: 10px;
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
  width: 100%;
  border-radius: 6px;
  svg {
    font-size: 17px;
  }
  &:hover {
    background-color: var(--grey100);
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    width: 64px;
    height: 64px;
  }
}

.list {
  li:not(:last-child) {
    margin-bottom: 8px;
  }
}

.noCustomer {
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-top: 36px;
}

.totalTime {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  min-height: 42px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  position: relative;
  z-index: 1;
  width: max-content;
  color: black;
  background-color: var(--grey300);
}