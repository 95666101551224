.wrapper {
  height: 70vh;
  width: 100vw;
  position: relative;
  bottom: -123px;
  padding: 16px 20px;
  overflow-y: auto;
  background-color: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  :global(.m-search) {
    margin-bottom: 24px;
  }
  @media screen and (min-width: 1024px) {
    bottom: inherit;
    height: 100vh;
    padding: 32px 40px;
    width: 496px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}

.head {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
  button {
    width: 42px;
    height: 42px;
  }
}

.title {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}

.settings {
  margin-left: auto;
}

.missions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

.missionsList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}
