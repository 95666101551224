.container {
  @media screen and (min-width: 1120px) {
    width: 100%;
    padding: 0 24px;
    max-width: 1320px;
    margin: 0 auto;
  }
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 2vh;
  @media screen and (min-height: 800px) {
    top: 10vh;
  }
  svg {
    width: 100px;
    height: 100px;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
  @media screen and (min-height: 600px) {
    padding-top: 80px;
  }
}

.title {
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  color: #fff;
  margin-bottom: 40px;
}

.input {
  margin-bottom: 32px;
}

.left,
.right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 50%;
  }
  @media screen and (min-width: 1120px) {
    width: 100%;
    max-width: 538px;
  }
  @media screen and (min-height: 600px) {
    height: 520px;
  }
  @media screen and (min-height: 800px) {
    height: 560px;
  }
}

.left {
  background-color: #000;
  padding: 80px 72px;
}

.remember {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #fff;
  }
}

.right {
  background-color: #fff;
  padding: 55px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}

.card {
  display: flex;
  gap: 24px;
  align-items: flex-start;
  .icon {
    border-radius: 100px;
    min-width: 56px;
    height: 56px;
    background-color: var(--grey300);
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      display: flex;
      font-size: 28px;
      width: 28px;
      height: 28px;
      color: #000;
      fill: #000;
    }
  }
  .subtitle {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    padding-top: 10px;
    color: #000;
    margin-bottom: 8px;
  }
  .cardText {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #646464;
  }
}

.passwordInput {
  display: flex;
  align-items: center;
  background-color: var(--grey700);
  svg {
    margin-top: 4px;
    margin-right: 8px;
    color: var(--grey500);
    &:hover {
      color: var(--grey200);
    }
  }
}
