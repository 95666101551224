.options {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: 1024px) {
    align-items: flex-start;
  }
}

.optionsRight {
  @extend .options;
  position: inherit;
  @media screen and (min-width: 1024px) {
    margin-bottom: 24px;
  }

  .optionsActions {
    @media screen and (min-width: 1024px) {
      position: absolute;
      top: 24px;
      right: 24px;
      left:inherit;
      bottom: inherit;
      transform: none;
    }

  }
}

.id {
  color: var(--grey400);
  font-size: 13px;
  font-weight: 700;
}

.optionsActions {
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 2;
  height: 72px;
  background-color: #fff;
  box-shadow: 0px -12px 52px 0px rgba(0, 0, 0, 0.11), 0px -1px 7px 0px rgba(0, 0, 0, 0.08);
  gap: 8px;
  padding: 0 24px;

  @media screen and (min-width: 528px) {
    width: auto;
    position: relative;
    box-shadow: none;
    height: auto;
    background-color: transparent;
    padding: 0;
  }

  @media screen and (min-width: 1024px) {
    transform: translate(24px, -16px);
  }




  button {
    &:first-child {
      @media screen and (min-width: 528px) {
        order: 2
      }
    }

    &:nth-child(2) {
      @media screen and (min-width: 528px) {
        order: 1;
      }

    }

    &:nth-child(3) {
      @media screen and (min-width: 528px) {
        order: 2;
      }

    }
  }
}

.optionsWrapper {
  position: relative;

}

.editOptions {
  z-index: 2;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;

  @media screen and (min-width: 528px) {
    top: calc(100% + 8px);
    position: absolute;
    bottom: inherit;
    right: 0;
    left: inherit;
    width: max-content;
  }
}

.dropdownItem {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 8px;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  gap: 10px;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  width: 100%;
  border-radius: 6px;

  svg {
    font-size: 17px;
  }

  &:hover {
    background-color: var(--grey100);
  }
}

.submit {
  margin-left: auto;

  @media screen and (min-width: 528px) {
    display: none;
  }
}