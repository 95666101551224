.wrapper {
  padding: 24px 0;
  position: relative;
  @media screen and (min-width: 768px) {
    height: 100vh;
    padding: 40px 0;
  }
}

.title {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  @media screen and (min-width: 423px) {
    font-size: 24px;
  }
  @media screen and (min-width: 768px) {
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 24px;
  :global(.m-search) {
    max-width: 45%;
    @media screen and (min-width: 1024px) {
      max-width: 289px;
    }
  }
  @media screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }
}

.leftHeader {
  display: flex;
  align-items: center;
  column-gap: 12px;
  width: 100%;
  @media screen and (min-width: 500px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
  }
  @media screen and (min-width: 1024px) {
    width: 40%;
    flex-direction: row;
    align-items: center;
    column-gap: 16px;
  }
}

.buttonsRight {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  :global(.m-button) {
    display: flex;
    justify-content: center;
    width: 50%;
    @media screen and (min-width: 1024px) {
      width: fit-content;
    }
  }
  :global(.m-button--white) {
    justify-content: space-between;
    width: 100%;
  }
  @media screen and (min-width: 500px) {
    justify-content: flex-start;
  }
  @media screen and (min-width: 1024px) {
    width: 100%;
    flex-direction: row;
    column-gap: 16px;
  }
  @media screen and (min-width: 1240px) {
    width: 100%;
    justify-content: flex-end;
    flex-direction: row;
    column-gap: 16px;
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    width: 64px;
    height: 64px;
  }
}

.voidContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px - 42px);
}

.content {
  padding-top: 40px;
  min-height: calc(100vh - 80px - 42px);
}

.dropdownContainer {
  position: relative;
}

.dropdown {
  position: absolute;
  top: calc(100% + 2px);
  left: 0%;
  min-width: 203px;
  @media screen and (min-width: 768px) {
    left: -25%;
  }
}

.filterButton {
  display: none;
  @media screen and (min-width: 1024px) {
    display: block;
  }
}

.dropdownItem {
  display: flex;
  align-items: center;
  padding: 10px 8px;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  gap: 10px;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  width: 100%;
  border-radius: 6px;
  svg {
    font-size: 17px;
  }
  &:hover {
    background-color: var(--grey100);
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  row-gap: 24px;
  padding-bottom: 24px;
  li {
    padding: 0 12px;
    width: 100%;
    @media screen and (min-width: 768px) {
      width: 50%;
    }
    @media screen and (min-width: 1178px) {
      width: 25%;
    }
  }
}
