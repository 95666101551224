.container {
  position: fixed;
  z-index: 11;
  bottom: 0;
  left: 0;
  height: 72px;
  width: 100vw;
  max-width: 100vw;
  background-color: #000;
  padding: 20px 0 20px 24px;
  display: flex;
  align-items: center;
  column-gap: 16px;
  @media screen and (min-width: 1024px) {
    padding: 20px 24px 20px 20px;
    top: 0;
    left: 0;
    height: 100vh;
    width: 80px;
    flex-direction: column;
    row-gap: 16px;
  }
}

.logo {
  display: none;
  @media screen and (min-width: 1024px) {
    display: block;
    width: 48px;
    height: 55px;
    margin-bottom: 8px;
    svg {
      width: 48px;
      height: 55px;
    }
  }
}

.menuItem {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  @media screen and (min-width: 1024px) {
    align-items: flex-start;
    width: auto;
  }
  &:hover {
    .toolTip {
      opacity: 1;
      transform: translate(25px, -50%);
    }
  }
}

.openMobileSubmenu {
  position: absolute;
  top: -172px;
  left: 0;
  width: 100%;
  max-width: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 24px 4px 24px;
  border-radius: 16px 16px 0px 0px;
  .submenuItem {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    a,
    button {
      display: flex;
      align-items: center;
      column-gap: 16px;
      color: #fff;
      font-size: 15px;
      font-weight: 700;
    }
  }
}

.menuItemMobileButton {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  &:hover {
    .toolTip {
      opacity: 1;
      transform: translate(25px, -50%);
    }
  }
  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.menuItemFar {
  position: relative;
  width: 100%;
  &:hover {
    .toolTip {
      opacity: 1;
      transform: translate(25px, -50%);
    }
  }
  @media screen and (min-width: 1024px) {
    margin-top: auto;
    width: auto;
  }
}

.toolTip {
  display: none;
  @media screen and (min-width: 1024px) {
    pointer-events: none;
    opacity: 0;
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(0px, -50%);
    transition: all 0.2s ease;
    svg {
      width: 8px;
      display: flex;
    }
    span {
      white-space: nowrap;
      border-radius: 8px;
      padding: 8px;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      color: #fff;
      background-color: #000;
    }
  }
}

.icon {
  display: flex;
  svg {
    width: 100%;
    max-width: 32px;
    font-size: 28px;
    color: #6e6e6e;
    fill: #6e6e6e;
  }
  &:not(.active):hover {
    svg {
      color: #b5b5b5;
      fill: #b5b5b5;
    }
  }
  @media screen and (min-width: 1024px) {
    padding: 8px;
    svg {
      fill: #6e6e6e;
      color: #6e6e6e;
      font-size: 28px;
      height: 28px;
      width: 28px;
    }
    &:not(.active):hover {
      svg {
        color: #b5b5b5;
        fill: #b5b5b5;
      }
    }
  }
}

.active {
  svg {
    color: #fff;
    fill: #fff;
  }
}
