.wrapper {
  height: 100vh;
  max-width: 528px;
  width: 100%;
  position: relative;
  background-color: #fff;

  @media screen and (min-width: 1024px) {
    width: 528px;
  }
}

.modal {
  background-color: #fff;
  height: 100vh;

  @media screen and (min-width: 1024px) {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: relative;
  }

  .form {
    @media screen and (min-width: 1024px) {
      width: 50%;
    }
    @media screen and (min-width: 1232px) {
      width: calc(100% - 528px);
    }
  }

  .notesBox {
    @media screen and (min-width: 1024px) {
      position: absolute;
      top: 0;
      width: 50%;
      height: 100%;
      right: 0;
      background-color: var(--grey100);
      padding: 96px 48px 48px;
      overflow: auto;
      max-width: 528px;
    }
  }

  .missions {
    margin-bottom: 32px;
    @media screen and (min-width: 1024px) {
      display: grid;
      margin-bottom: 40px;
      grid-gap: 24px;
      gap: 24px;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
    @media screen and (min-width: 1600px) {
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    }
    li {
      width: auto;
      margin-bottom: 16px;
      @media screen and (min-width: 1024px) {
        flex-shrink: 0;
        margin-bottom: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .bottom {
    button {
      width: auto;
    }
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 100px;
    height: 100px;
  }
}

.form {
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  height: -moz-available;
  /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  height: stretch;
  padding: 24px;
  @media screen and (min-width: 1024px) {
    padding: 40px 48px 32px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.head {
  margin-bottom: 32px;
  @media screen and (min-width: 1024px) {
    margin-bottom: 40px;
  }
  &__display {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .displayClose {
      position: absolute;
      top: -4px;
      right: 0px;
      margin-left: 8px;
    }
  }
}

.padding {
  padding: 0 24px;
}

.name {
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  width: 90%;
  overflow: hidden;
  overflow-wrap: break-word;
  resize: none;

  &::placeholder {
    color: #000;
    opacity: 0.3;
  }
}

.infoRow {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 8px;

  @media screen and (min-width: 528px) {
    gap: 12px;
    margin-top: 12px;
  }
}

.info {
  height: 42px;
  padding: 0 24px;
  border-radius: 100px;
  border: 2px solid var(--grey300);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: var(--grey600);

  &:hover {
    border-color: var(--grey400);
  }
}

.complexity {
  padding: 0 18px;
  border-radius: 100px;
  border: 2px solid var(--grey300);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: var(--grey600);
  height: 42px;
  display: inline-flex;
  align-items: center;

  &:hover {
    border-color: var(--grey400);
  }

  input {
    width: 76px;
    border-radius: 100px;
    border: none;
    color: #000;
    font-size: 14px;
    font-weight: 500;

    &:focus {
      outline: none;
    }
  }

  p {
    color: #000;
    font-weight: 500;
    font-size: 14px;
  }

  button {
    cursor: pointer;
    margin-right: 8px;
    line-height: 0;

    &:hover {
      svg {
        color: var(--grey600);
      }
    }

    svg {
      flex-shrink: 0;
      font-size: 18px;
      color: var(--grey500);
    }
  }
}

.tasks {
  position: relative;
  z-index: 1;
  margin-bottom: 32px;

  @media screen and (min-width: 1024px) {
    margin-bottom: 40px;
  }
}

input.info {
  width: 129px;
}

.addMission {
  border: 2px solid var(--grey300);
  border-radius: 100px;
  display: flex;
  height: 42px;
  width: 42px;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: var(--grey400);
  }

  svg {
    width: 18px;
    height: 18px;
    color: #000;
  }
}

.subtitle {
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}

.addTask {
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;

  svg {
    fill: #000;
    font-size: 18px;
  }
}

.notesBox {
  position: relative;
  margin-bottom: 32px;
  @media screen and (min-width: 1024px) {
    margin-bottom: 40px;
  }
}

.notes {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  color: var(--grey800);
  resize: vertical;
  padding: 2px;
  width: 100%;
  height: 100px;
  resize: none;

  &::placeholder {
    color: #000;
    opacity: 0.5;
  }
}

.errorMessage {
  border: 2px solid #f65555;
  border-radius: 12px;
  background: #fde1e1;
  color: #dc1b1b;
  padding: 16px 40px;
  text-align: center;
  margin-bottom: 16px;
  font-size: 13px;
  font-weight: 500;
}

.bottom {
  display: none;

  @media screen and (min-width: 528px) {
    display: block;
    margin-top: auto;
    position: sticky;
    bottom: 0px;
    z-index: 2;
  }
}

.bottomCreate {
  @extend.bottom;
  display: block;
  margin-top: auto;
  position: sticky;
  bottom: 0px;
}

.missions {
  margin-bottom: 32px;
  @media screen and (min-width: 1024px) {
    margin-bottom: 40px;
  }
  li {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
