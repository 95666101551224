.sprint {
    &:not(:last-child) {
        margin-bottom: 24px;
    }
}

.container {
    width: 100%;
    background-color: var(--grey200);
    transition: background-color 0.1s ease-in;
    padding: 0 12px 0 0;
    position: relative;
    &.open {
        padding: 16px 12px;
        .openSprint {
            padding: 0;
        }
    }
    &:not(.open):hover {
        background-color: var(--grey300);
    }
}

.hovered {
    background-color: #aab1c733 !important;
}

.openSprint {
    width: 100%;
    padding: 24px 0 24px 12px;
}

.sprintInfo {
    align-items: center;
    display: flex;
}

.progress {
    font-size: 10px;
    font-weight: 700;
    line-height: 13px;
    color: #fff;
    background-color: var(--red);
    padding: 4px 10px 4px 19px;
    border-radius: 17px;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        width: 5px;
        height: 5px;
        background-color: #fff;
        border-radius: 50%;
    }
}

.open {
    height: 100%;
    overflow: auto;
}

.stories {
    height: calc(100% - 80px);
    overflow-y: scroll;
    margin-top: 16px;
    .story:not(:last-child) {
        margin-bottom: 8px;
    }
}

.addNew {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: var(--grey600);
    svg {
        width: 18px;
        height: 18px;
        margin-right: 8px;
        color: var(--grey600);
    }
    &:hover {
        color: var(--grey900);
        svg {
            color: var(--grey900);
        }
    }
}
