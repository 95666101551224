.header {
  display: flex;
  align-items: center;
}

.noHeader {
  display: none;
}

.add {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.storiesContainer {
  padding: 16px 0;
  height: inherit;
}

.stories {
  height: calc(100% - 46px);
  overflow-y: auto;
  overflow-x: hidden;
  .story:not(:last-child) {
    margin-bottom: 8px;
  }
}

.singleStories {
  margin-bottom: 16px;
}

.addNew {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: var(--grey600);
  svg {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    color: var(--grey600);
  }
  &:hover {
    color: var(--grey900);
    svg {
      color: var(--grey900);
    }
  }
}

.single {
  bottom: inherit;
}
