.wrapper {
  height: 100vh;
  width: 100%;
  position: relative;
  padding: 32px;
  overflow-y: auto;
  background-color: #fff;
  @media screen and (min-width: 768px) {
    width: 528px;
    padding: 48px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  p {
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
  }
}

.buttons {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.input:not(:last-child) {
  margin-bottom: 32px;
}

.flexInput {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 32px;
}
