.title {
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: var(--grey900);
    margin-bottom: 32px;
}

.container {
    // max-width: 648px;
    padding: 24px;
    background-color: #fff;
    border-radius: 16px;
    & > div > div:first-of-type {
        display: flex;
        justify-content: space-between;
        gap: 15px;
    }
    & > div > div > div:nth-child(3) > div > div {
        & > button:last-child {
            display: none;
        }
        & p {
            margin: 0 auto;
        }
    }
    & > div > div > div:nth-child(4) > div > div {
        & > button:first-child {
            display: none;
        }
        & p {
            margin: 0 auto;
        }
    }

    & > div > div > div:nth-child(4) {
        display: none;
        @media screen and (min-width: 768px) {
            display: block;
        }
    }

    & > div > div > div:nth-child(4) .increaseMonth {
        @media screen and (min-width: 768px) {
            display: flex !important;
        }
    }

    .increaseMonth {
        display: block !important;
        @media screen and (min-width: 768px) {
            display: none !important;
        }
    }
}

.calendarHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;
    p {
        font-size: 13px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        color: var(--grey900);
    }
}

.decreaseMonth,
.increaseMonth {
    display: flex;
    padding: 7px;
    border-radius: 100px;
    transition: 0.2s all ease;
    &:hover {
        background-color: var(--grey100);
    }
    svg {
        font-size: 10px;
        color: #646464;
    }
}
