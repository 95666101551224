.otherActions {
  top: calc(100% + 8px);
  position: absolute;
  min-width: 284px;
  z-index: 11;
  @media screen and (min-width: 1024px) {
    left: -10%;
  }
}

.actionItem {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 8px;
  border-radius: 6px;
  background-color: #fff;
  color: #000;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  width: 100% !important;
  justify-content: flex-start !important;
  &:hover {
    background-color: var(--grey100);
  }
}

.actionIcon {
  font-size: 17px;
  color: #000;
}

.actionArrow {
  font-size: 17px;
  color: var(--grey400);
  margin-left: auto;
}
