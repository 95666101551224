.wrapper {
  height: 100vh;
  width: 100vw;
  position: relative;
  padding: 12px;
  overflow-y: auto;
  transition: background-color 0.2s ease;
  @media screen and (min-width: 768px) {
    max-width: 528px;
    padding: 48px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  @media screen and (min-width: 768px) {
  }
}

.title {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}

.buttons {
  display: flex;
  align-items: center;
  column-gap: 8px;
  :last-child {
    height: 42px;
    width: 42px;
  }
}

.name {
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  width: 100%;
  margin-bottom: 32px;
  &::placeholder {
    color: #000;
    opacity: 0.3;
  }
}

.inputs {
  display: flex;
  align-items: center;
  gap: 8px;
}
