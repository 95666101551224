.wrapper {
    height: 100vh;
    padding: 24px 24px;
    position: relative;

    @media screen and (min-width: 768px) {
        padding: 40px 0;
    }
}

.header {
    display: flex;
    align-items: center;
}

.title {
    font-size: 24px;
    font-weight: 700;
    line-height: 42px;
    color: #000000;
    margin-left: 24px;

    @media screen and (min-width: 768px) {
        font-size: 32px;
    }
}

.back {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    background-color: #000000;
    border-radius: 100px;

    svg {
        width: 18px;
        height: 18px;
        color: #ffffff;
    }
}

.container {
    margin-top: 24px;
    padding-bottom: 40px;

    @media screen and (min-width: 768px) {
        margin: 80px auto 0 auto;
        width: 50%;
        min-width: 624px;
    }
}

.dropdownContainer {
    position: relative;
}

.dropdown {
    top: calc(100% + 8px);
    left: 0;
    position: absolute;
    min-width: 161px;
    z-index: 3;

    div:first-child {
        bottom: 73px;

        @media screen and (min-width: 1024px) {
            bottom: initial;
        }
    }
}

.sprintsList {
    max-height: 250px;
    overflow-y: auto;
}

.actionItem {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 8px;
    border-radius: 6px;
    background-color: #fff;
    color: #000;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    width: 100%;

    &:hover {
        background-color: var(--grey100);
    }
}

.date {
    margin-left: 20px;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    color: var(--grey500);
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
        width: 18px;
        height: 18px;
    }

    @media screen and (min-width: 500px) {
        margin-left: 0;
    }
}

.sprintHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.statsList {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 24px;

    @media screen and (min-width: 700px) {
        align-items: center;
        flex-wrap: nowrap;
        gap: 24px;
    }
}

.statsItem {
    background-color: var(--grey200);
    padding: 24px;
    border-radius: 8px;
    width: 100%;

    &__double {
        width: calc(50% - 4px);
    }

    @media screen and (min-width: 700px) {
        width: 33%;
        flex: initial;
        min-width: initial;
    }
}

.statsData {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    color: #000;
}

.statsInfo {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: #646464;
}

.stories {
    margin-top: 24px;
}