h2 {
  font-size: 20px;
  @media screen and (min-width: 769px) {
    font-size: 24px;
  }
}

.headerWrapper {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 800px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.historyHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 16px;
  margin: 24px 0;
  @media screen and (min-width: 768px) {
    align-items: center;

    flex-wrap: nowrap;
  }
  .missionsDropdown {
    :global(.m-button):first-child {
      svg {
        transform: rotate(90deg);
      }
    }
  }
}

.personsDropdown {
  position: relative;
}

.missionsDropdown {
  position: relative;
  @media screen and (min-width: 1024px) {
    margin-left: auto;
  }
}

.missionsDropdownContent {
  position: absolute;
  top: calc(100% + 2px);
  min-width: 212px;
  max-width: 300px;
}

.filterSelected {
  padding-right: 42px;
}

.resetMissionFilter {
  display: flex;
  position: absolute;
  font-size: 18px;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  color: var(--grey400);
  z-index: 2;
  &:hover {
    transition: color 0.3s ease-in;
    color: var(--grey700);
  }
}

.historyList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.calendarDropdown {
  position: relative;
}

.calendarDropdownContent {
  position: absolute;
  top: calc(100% + 2px);
  right: 0;
  min-width: 212px;
}

.dateText {
  white-space: nowrap;
}

.noTimer {
  margin-top: 96px;
  color: #646464;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.totalTime {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  min-height: 42px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  position: relative;
  z-index: 1;
  width: max-content;
  color: black;
  background-color: var(--grey300);
}
