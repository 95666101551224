.container {
  display: flex;
  align-items: center;
}

.titleContainer {
  border-radius: 100px 0 0 100px;
  padding: 4px 6px 4px 8px;
  max-width: 80%;
}

.titleContainerMainTimer {
  border-radius: 100px 0 0 100px;
  padding: 4px 6px 4px 8px;
  max-width: 80%;
  height: 26px;
}

.titleOnlyContainer {
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 4px 8px;
}

.titleOnlyContainerMainTimer {
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 4px 8px;
  height: 26px;
}

.title {
  font-size: 10px;
  font-weight: 600;
  line-height: 13px;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.titleMainTimer {
  font-size: 10px;
  font-weight: 600;
  line-height: 19px;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text {
  font-size: 10px;
  font-weight: 600;
  line-height: 13px;
  color: #000;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.titleOnly {
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 13px;
  color: #000;
  border-radius: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.large {

  .title,
  .text,
  .titleOnly {
    font-size: 12px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    @media screen and (min-width: 1024px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  // .title {
  //     padding: 12px 12px 12px 16px;
  // }
  // .text {
  //     padding: 12px 16px 12px 12px;
  // }
  // .titleOnly {
  //     padding: 12px 16px;
  // }
}

.large {
  cursor: pointer;
  .titleContainer,
  .titleContainerMainProject {
    height: 40px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    @media screen and (min-width: 1024px) {
      padding: 0 12px;
    }
  }

  .project,
  .projectMainProject {
    height: 40px;
    padding: 0 10px;
    @media screen and (min-width: 1024px) {
      padding: 0 12px;
    }
  }

  &.titleOnlyContainer {
    height: 40px;
    padding: 0 10px;
    @media screen and (min-width: 1024px) {
      padding: 0 12px;
    }
  }
}

.deleteButton {
  display: flex;
  width: fit-content;
  margin-left: 4px;

  svg {
    font-size: 12px;
    color: #000;
    opacity: 0.3;

    &:hover {
      opacity: 0.6;
    }
  }
}

.deleteButtonStory {
  display: flex;
  width: fit-content;
  margin-left: 4px;

  svg {
    font-size: 18px;
    color: #000;
    opacity: 0.3;

    &:hover {
      opacity: 0.6;
    }
  }
}

.project {
  display: flex;
  align-items: center;
  padding: 4px 8px 4px 6px;
  border-radius: 0 100px 100px 0;
  max-width: 211px;
}

.projectMainTimer {
  display: flex;
  align-items: center;
  padding: 4px 8px 4px 6px;
  border-radius: 0 100px 100px 0;
  height: 26px;
}