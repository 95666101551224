.wrapper {
  min-height: 100vh;
  padding: 40px 0;
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.title {
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  color: #000000;
}

.defaultLoading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    width: 64px;
    height: 64px;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px - 42px);
}

.loading {
  display: flex;
  justify-content: center;
  svg {
    height: 80px;
  }
}
