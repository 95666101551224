.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 624px;
  margin: 0 auto;
  padding: 72px 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
}

.header {
  margin-bottom: 24px;
  width: 100%;
  .top {
    display: flex;
    align-items: center;
    column-gap: 24px;
    margin-bottom: 24px;
    :global(.m-button--black) {
      width: 42px;
      height: 42px;
      padding: 12px;
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      rotate: 90deg;
    }
  }
}

.storiesWrapper {
  max-width: 624px;
  max-height: 682px;
  background-color: var(--grey200);
  padding: 0px 12px 16px 12px;
  overflow: scroll;
  position: relative;
  transition: background-color 0.1s ease-in;
  @media screen and (min-width: 768px) {
    width: 624px;
  }
}

.dropdownContainer {
  margin: 0 16px;
  position: relative;
}

.dropdown {
  top: calc(100% + 8px);
  left: 0%;
  transform: translateX(0%);
  position: absolute;
  min-width: 161px;
  z-index: 10;
}

.dropdownButton {
  svg {
    transform: rotate(90deg);
    width: 18px;
    height: 18px;
  }
}

.filterButton {
  position: relative;
}

.filterActive {
  padding-right: 42px;
}

.resetFilter {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  display: flex;
  svg {
    width: 18px;
    height: 18px;
    fill: var(--grey400);
    &:hover {
      fill: var(--grey600);
    }
  }
}
