.container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  row-gap: 8px;
  padding: 16px;
  background-color: #fff;
  align-items: center;
  width: 100%;
  @media screen and (min-width: 1024px) {
    flex-wrap: nowrap;
    padding: 17px 24px;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.greyContainer {
  background-color: #f1f4f9;
}

.input {
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  color: #000;
  max-width: 250px;
  &::placeholder {
    color: #6e6e6e;
  }
  &.inputFill {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.rightContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30%;
  @media screen and (min-width: 1024px) {
    width: 100%;
    justify-content: flex-end;
  }
}

.nameContainer {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-right: 16px;
  width: 70%;
  @media screen and (min-width: 600px) {
    width: 100%;
  }
}

.subtaskContainer {
  margin-right: 16px;
  width: 35%;
}

.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  width: 70%;
  @media screen and (min-width: 600px) {
    width: 100%;
  }
}

.addMissionContainer {
  position: relative;
}

.addMissionButton {
  display: flex;
  gap: 6px;
}

.addMissionDropdown {
  position: absolute;
  top: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 312px;
}

.addCustomer,
.addMission {
  display: flex;
  align-items: center;
  padding: 4px 12px 4px 8px;
  background-color: var(--grey100);
  border-radius: 100px;
  white-space: nowrap;
  svg {
    width: 13px;
    height: 13px;
    color: var(--grey400);
    margin-right: 4px;
  }
  p {
    color: var(--grey600);
    font-size: 12px;
    font-weight: 600;
  }
  &:hover {
    background-color: var(--grey200);
  }
}

.button {
  padding: 0px;
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 100px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.1s ease-in;
  &:hover {
    background-color: var(--grey200);
  }
  svg {
    width: 18px;
    height: 18px;
    color: var(--grey600);
  }
  @media screen and (min-width: 768px) {
    padding: 8px 12px;
  }
}

.time {
  display: flex;
  align-items: center;
  column-gap: 24px;
  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
}

.dateTimer {
  width: 150px;
}

.dateTimerGrey {
  margin: 0 92px 0 auto;
}

.duration {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  // flex-shrink: 0;
}

.durationGrey {
  margin-right: 32px;
}

.options {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  &__align {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    column-gap: 8px;
  }
  @media screen and (min-width: 1024px) {
    position: inherit;
    transform: translateY(0);
    margin-left: 32px;
  }
}

.optionsDropdown {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  z-index: 9999;
  width: 229px;
}

.optionsButton {
  padding: 4px;
  display: flex;
  svg {
    width: 18px;
    height: 18px;
    color: var(--grey400);
    fill: var(--grey400);
  }
  &:hover {
    svg {
      color: var(--grey600);
      fill: var(--grey600);
    }
  }
}

.actionItem {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 8px;
  border-radius: 6px;
  background-color: #fff;
  color: #000;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  width: 100%;
  margin-right: 16px;
  &:hover {
    background-color: var(--grey100);
  }
  svg {
    min-width: 17px;
    min-height: 17px;
  }
}

.play {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: #000;
  position: relative;
  z-index: 1;
  margin-right: 0px;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100px;
    transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    z-index: -1;
    background-color: transparent;
    background-color: #000;
  }
  &:hover {
    &::after {
      transform: scale(1.1);
    }
  }
  svg {
    width: 12px;
    height: 12px;
    color: #fff;
  }
}
