.m-search {
    position: relative;
    width: 100%;
    input {
        width: 100%;
        background-color: var(--grey200);
        border-radius: 32px;
        padding: 12px 16px;
        &::placeholder {
            color: var(--grey500);
        }
        &:focus {
            border-color: #000;
        }
        &:hover {
            background-color: var(--grey300);
        }
    }
    &--white {
        input {
            background-color: #fff;
        }
    }
    svg {
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
        font-size: 18px;
        color: #000;
    }
}
