.wrapper {
  position: fixed;
  width: fit-content;
  height: 100vh;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 1024px) {
    width: 100vw;
    height: 100vh;
    left: 0;
    transform: translateX(0);
  }
}

.popup {
  position: fixed;
  bottom: 0px;
  width: 100vw;
  background-color: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  @media screen and (min-width: 1024px) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    position: relative;
    width: 515px;
  }
}
