.m-popup {
  &__header {
    padding: 13px 40px;
    background-color: var(--grey100);
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.2s ease;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    @media screen and (min-width: 1024px) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    p {
      font-size: 15px;
      font-weight: 700;
      line-height: 20px;
      color: #000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__close {
      padding: 12px;
      display: flex;
      border-radius: 100px;
      background-color: rgba(0, 0, 0, 0.06);
      display: flex;
      position: relative;
      z-index: 1;
      transition: background-color 0.2s ease;
      width: 42px !important;
      height: 42px !important;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 100px;
        transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1),
          background-color 0.25s ease;
        z-index: -1;
        background-color: transparent;
      }
      &:hover {
        background-color: transparent;
        &::after {
          transform: scale(1.1);
          background: rgba(0, 0, 0, 0.16);
        }
      }
      svg {
        font-size: 18px;
        color: #000;
      }
    }
  }
  &__content {
    padding: 12px;
    @media screen and (min-width: 768px) {
      padding: 32px 40px 40px 40px;
    }
    &__text-grey {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 32px;
      color: #646464;
      b {
        color: #000;
        font-weight: 700;
      }
    }
  }
}
