.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
  @media screen and (min-width: 1024px) {
    flex-wrap: nowrap;
  }
  h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    margin-right: auto;
  }
  :global(.m-search) {
    max-width: 147px;
    @media screen and (min-width: 514px) {
      max-width: 289px;
    }
  }
}

.dropdownContainer {
  position: relative;
}

.dropdown {
  position: absolute;
  top: calc(100% + 2px);
  left: -25%;
  min-width: 203px;
}

.dropdownItem {
  display: flex;
  align-items: center;
  padding: 10px 8px;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  gap: 10px;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  width: 100%;
  border-radius: 6px;
  svg {
    font-size: 17px;
  }
  &:hover {
    background-color: var(--grey100);
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  row-gap: 24px;
  padding-bottom: 24px;
  li {
    padding: 0 12px;
    width: 100%;
    @media screen and (min-width: 768px) {
      width: 50%;
    }
    @media screen and (min-width: 1178px) {
      width: 25%;
    }
  }
}

.empty {
  margin-top: 200px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #646464;
}
