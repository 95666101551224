.container {
  padding-bottom: 48px;

  @media screen and (min-width: 1024px) {
    width: 268px;
  }
}

.list {
  max-height: 400px;
  overflow-y: auto;
  position: relative;
  margin-top: 16px;
  li:not(:last-child) {
    margin-bottom: 2px;
  }
}

.actionItem {
  display: flex;
  align-items: center;
  padding: 10px 8px;
  border-radius: 6px;
  background-color: #fff;
  color: #000;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  width: 100%;

  &:hover {
    background-color: var(--grey100);
  }
}

.customerDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  flex-shrink: 0;
}

.customerText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.picture {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.profilSvg {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: var(--grey300);
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 12px;
    height: 12px;
  }
}

.actionItemUser {
  display: flex;
  align-items: center;
  padding: 7px 8px;
  border-radius: 6px;
  background-color: #fff;
  color: #000;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  width: 100%;
  &:hover {
    background-color: var(--grey100);
  }
}

.me {
  position: fixed;
  width: calc(100% - 32px);
  left: 50%;
  transform: translateX(-50%);
  bottom: 16px;
}

.actionItemUserMe {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 8px;
  border-radius: 6px;
  background-color: var(--grey200);
  color: #000;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  width: 100%;
  &:hover {
    background-color: var(--grey300);
  }
}

.points {
  border: 1.4px solid var(--grey300);
  border-radius: 17px;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
  color: var(--grey500);
  margin-left: 8px;
}

.test {
  position: fixed;
  width: 95%;
  padding-right: 15px;
  bottom: 16px;

    @media screen and (min-width: 1024px) {
    width: 268px;
    padding: 0;
  }
}
