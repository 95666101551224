.input {
    font-size: 13px;
    font-weight: 700;
    line-height: 17px;
    color: #fff;
    margin-left: 8px;
    &::placeholder {
        color: #6e6e6e;
    }
}

.container {
    position: absolute;
    bottom: 100%;
    right: 0;
    width: calc(100% - 32px);
    background-color: var(--grey800);
    border-radius: 14px 14px 0 0;
}

.suggestionsContainer {
    padding: 24px;
    display: flex;
    overflow-x: auto;
}

.suggestions {
    color: #a7a7a7;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-right: 8px;
    line-height: 21px;
}

.tags {
    display: flex;
    align-items: center;
    gap: 8px;
}

.selectedMissions {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-right: 8px;
}

.addMission {
    min-width: 21px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.16);
    transition: background-color 0.25s ease;
    svg {
        width: 13px;
        height: 13px;
        color: #fff;
    }
    &:hover {
        background-color: rgba(255, 255, 255, 0.24);
    }
}
