.container {
  width: 100%;
  padding: 0 16px;

  // PAdding vu avec Manu
  @media screen and (min-width: 768px) {
    padding: 0 40px;
  }
}

.layout-container {
  max-width: 100vw;
  // overflow: hidden;
  height: calc(100vh - 72px);
  // margin-bottom: 200px;

  @media screen and (min-width: 1024px) {
    margin-left: 80px;
    min-height: 100vh;
    margin-bottom: 0;
  }
}

.sr-only {
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  line-height: 0;
  text-indent: -6.25rem;
  width: 1px;
  white-space: nowrap;
  z-index: -1;
  position: absolute;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  margin: -1px;
  outline: none;
  overflow: hidden;
  padding: 0px;
}

.react-datepicker {
  &__triangle {
    display: none;
  }

  &__aria-live {
    display: none;
  }

  .react-datepicker__day-name {
    display: inline-flex;
    width: 40px;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    color: #646464;
    margin-bottom: 16px;
  }

  .react-datepicker__week {
    display: flex;
    width: fit-content;

    .react-datepicker__day {
      display: inline-flex;
      cursor: pointer;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      border-radius: 100px;
      color: #000;

      &.react-datepicker__day--outside-month {
        color: var(--grey400);

        &.react-datepicker__day--selected {
          pointer-events: none;
          display: inline-flex;
          width: 40px;
          height: 40px;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          font-weight: 500;
          line-height: 16px;
          opacity: 0;
        }

        &.react-datepicker__day--in-range {
          pointer-events: none;
          display: inline-flex;
          width: 40px;
          height: 40px;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          font-weight: 500;
          line-height: 16px;
          opacity: 0;
        }

        &.react-datepicker__day--in-selecting-range {
          pointer-events: none;
          display: inline-flex;
          width: 40px;
          height: 40px;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          font-weight: 500;
          line-height: 16px;
          opacity: 0;
        }
      }

      &.react-datepicker__day--selected,
      &.react-datepicker__day--in-range {
        background-color: #000;
        color: #fff;
      }

      &.react-datepicker__day--in-selecting-range {
        background-color: var(--grey200);
      }

      &.react-datepicker__day--in-range,
      &.react-datepicker__day--in-selecting-range {
        border-radius: 0;
        background-color: #f0f0f0;
        color: #000;
      }

      &.react-datepicker__day--range-start {
        border-radius: 100px 0 0 100px;
        background-color: #000;
        color: #fff;
      }

      &.react-datepicker__day--range-end {
        border-radius: 0 100px 100px 0;
        background-color: #000;
        color: #fff;
      }

      &.react-datepicker__day--range-start.react-datepicker__day--range-end {
        border-radius: 100px;
        background-color: #000;
        color: #fff;
      }

      &.react-datepicker__day--selecting-range-start {
        border-radius: 100px 0 0 100px;
        background-color: #000;
        color: #fff;
      }

      &.react-datepicker__day--selecting-range-end {
        border-radius: 0 100px 100px 0;
        background-color: #000;
        color: #fff;
      }
    }
  }
}

.event-datepicker {
  .react-datepicker {
    max-width: inherit;
  }

  .react-datepicker .react-datepicker__week {
    width: auto;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #2f2f2f3b;
  border-radius: 12px;
}

// Wysiwyg