.dragContainer {
  margin-bottom: 8px;
  position: relative;
  cursor: grab;
  &:hover,
  &:focus {
    .play {
      opacity: 1;
    }
  }
}

.container {
  background-color: #fff;
  padding: 16px;
  width: 100%;
  text-align: left;
  transition: all 0.3s ease-in-out;
  overflow-x: hidden;
  &:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09),
      0px 12px 24px rgba(0, 0, 0, 0.08);
  }
}

.title {
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  color: #000;
  margin-bottom: 8px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
  margin-bottom: 16px;
  li {
    max-width: 90%;
  }
}

.footer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.pictures {
  display: flex;
}

.picture {
  width: 21px;
  height: 21px;
  border-radius: 100px;
  overflow: hidden;
  display: flex;
  outline: 2px solid #fff;
  z-index: 2; /* Higher z-index for the picture on the left */
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.pictureGrey {
  width: 21px;
  height: 21px;
  border-radius: 100px;
  overflow: hidden;
  display: flex;
  outline: 2px solid #fff;
  filter: grayscale(1);
  margin-left: -4px; /* Overlap the picture to the left */
  z-index: 1; /* Lower z-index for the picture on the right */
  position: relative; /* Establish relative positioning */
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/* Apply a higher z-index for each subsequent .pictureGrey */
.pictureGrey + .pictureGrey {
  z-index: 0;
}

.profilSvg {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: var(--grey300);
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 12px;
    color: #000;
  }
}

.points {
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
  color: var(--grey600);
  padding: 4px 10px;
  border: 1.4px solid var(--grey300);
  border-radius: 17px;
}

.tasks {
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
  color: var(--grey600);
}

.play {
  opacity: 0;
  position: absolute;
  bottom: 16px;
  right: 16px;
  margin-left: auto;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  z-index: 1;
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100px;
    transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    z-index: -1;
    background-color: #000;
  }
  &:hover {
    &::after {
      transform: scale(1.3);
    }
  }
  svg {
    font-size: 9px;
    color: #fff;
  }
  &:focus {
    opacity: 1;
  }
}

.pause {
  position: absolute;
  bottom: 16px;
  right: 16px;
  margin-left: auto;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  z-index: 1;
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100px;
    transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    z-index: -1;
    background-color: #000;
  }
  &:hover {
    &::after {
      transform: scale(1.3);
    }
  }
  svg {
    font-size: 9px;
    color: #fff;
  }
}
