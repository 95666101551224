.stack {
  padding: 17px 24px;
  background-color: #fff;
  cursor: pointer;
  &:hover {
    background-color: var(--grey200);
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 8px;
  @media screen and (min-width: 768px) {
    row-gap: 0;
    flex-wrap: nowrap;
  }
}

.stackCount {
  border-radius: 8px;
  background-color: var(--grey200);
  padding: 8px 12px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0px;
  &:hover {
    background-color: var(--grey300);
  }
  @media screen and (min-width: 403px) {
    margin-right: 16px;
  }
}

.name {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #000;
  margin-right: 16px;
  max-width: 80%;
  @media screen and (min-width: 768px) {
    width: 25%;
  }
}

.subTask {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: var(--grey500);
  margin-right: 16px;
}

.tags {
  display: flex;
  align-items: center;
  gap: 8px;
}

.duration {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  width: fit-content;
  @media screen and (min-width: 768px) {
    margin: 0 2px 0 auto;
  }
}

.options {
  width: 10%;
}

.singleTimer {
  &:nth-child(2) {
    margin-top: 16px;
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
