.container {
  position: relative;
  margin-left: auto;
}

.button {
  padding: 0px;
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 100px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.1s ease-in;
  &:hover {
    background-color: var(--grey200);
  }
  svg {
    width: 18px;
    height: 18px;
    color: var(--grey600);
  }
  span {
    width: 35px;
  }
  @media screen and (min-width: 768px) {
    padding: 8px 12px;
  }
}

.dropdown {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  z-index: 10;
}
