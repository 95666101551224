.wrapper {
    padding: 13px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--grey100);
    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
    }
    button,
    a {
        display: flex;
        svg {
            fill: var(--grey400);
            width: 24px;
            height: 24px;
        }
        &:hover {
            svg {
                fill: #000;
            }
        }
    }
}
