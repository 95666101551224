.head {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
  button {
    height: 42px;
    width: 42px;
  }
}

.back {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background-color: var(--grey200);
  border-radius: 100px;
  svg {
    width: 18px;
    height: 18px;
    fill: #000;
  }
}

.title {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}

.missions {
  .missionItem:not(:last-child) {
    margin-bottom: 16px;
  }
}
