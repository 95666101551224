.wrapper {
  height: 100vh;
  width: 100vw;
  position: relative;
  bottom: 0;
  padding: 48px;
  overflow-y: auto;
  transition: background-color 0.2s ease;
  @media screen and (min-width: 1024px) {
    max-width: 528px;
  }
}

.head {
  padding: 13px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s ease;
}

.title {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}

.close {
  background: rgba(0, 0, 0, 0.16);
  border-radius: 100px;
  padding: 12px;
  display: flex;
  position: relative;
  z-index: 1;
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100px;
    transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1),
      background-color 0.1s ease-in;
    z-index: -1;
    background-color: transparent;
  }
  &:hover {
    &::after {
      transform: scale(1.1);
    }
  }
  &:hover {
    background-color: transparent;
    &::after {
      background: rgba(0, 0, 0, 0.32);
    }
  }
  svg {
    width: 18px;
    height: 18px;
  }
}

.form {
  padding: 16px;
  @media screen and (min-width: 1024px) {
    padding: 32px 40px 40px 40px;
  }
}

.input {
  margin-bottom: 32px;
}

.name {
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  width: 100%;
  margin-bottom: 32px;
  &::placeholder {
    color: #000;
    opacity: 0.3;
  }
}

.inputs {
  display: flex;
  align-items: center;
  gap: 8px;
}
