.container {
  padding: 24px;
  display: flex;
  margin-bottom: 8px;
  position: relative;
  background-color: #fff;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    gap: 0;
  }
}

.profile {
  display: flex;

  @media screen and (min-width: 1024px) {
    align-items: center;
    width: 42%;
  }

  .userName {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;

    @media screen and (min-width: 1024px) {
      flex-direction: row;
      gap: 0;
    }

    .name {
      font-size: 14px;
      font-weight: 500;
    }

    .name,
    .item {
      @media screen and (min-width: 1024px) {
        width: 50%;
      }
    }
  }
}

.userInfos {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    gap: 0;
    width: 56%;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (min-width: 1024px) {
      width: 44%;

      &:nth-child(2),
      &:last-child {
        margin-left: 30px;
      }
    }

    label {
      color: var(--base-noir, #000);
      font-size: 12px;
      font-weight: 500;

      @media screen and (min-width: 1024px) {
        display: none;
      }
    }
  }
}

.item {
  color: #646464;
  font-size: 13px;
  font-weight: 500;
}

.edit {
  position: absolute;
  right: 24px;
  top: 45px;
  transform: translateY(-50%);

  svg {
    font-size: 18px;
    color: #000;
  }

  @media screen and (min-width: 1024px) {
    position: relative;
    right: 0;
    top: auto;
    transform: none;
    margin-left: auto;
  }
}

.roleButton {
  @extend .item;
  align-items: center;
  display: flex;
  text-align: right;

  svg {
    margin-left: 10px;
    font-size: 12px;
    color: #000;
    margin-right: 8px;
  }
}

.role {
  position: relative;
}

.roleDropdown {
  z-index: 2;
  position: fixed;
  width: 100%;
  bottom: 72px;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (min-width: 1024px) {
    position: absolute;
    top: 100%;
    left: 0;
    bottom: auto;
    width: 150px;
    transform: none;
  }

  div {
    position: relative;

    label {
      display: block !important;
      padding: 10px;
      border-radius: 6px;
      display: block;
      font-size: 13px;
      font-weight: 500;
      line-height: 17px;

      &:hover {
        background-color: var(--grey100);
      }
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;

      &:hover+label {
        background-color: var(--grey100);
      }

      &:checked+label {
        color: #000;
      }

      &:checked {
        cursor: default;
      }
    }
  }
}

.profilSvg,
.picture {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
  flex-shrink: 0;

  @media screen and (min-width: 1024px) {
    margin-right: 16px;
  }
}

.profilSvg {
  background-color: var(--grey300);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 14px;
    color: #000;
  }
}

.picture {
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.pictureSvg {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background-color: var(--grey300);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 48px;
  }
}

.wrapperPopup {
  height: 100vh;
  max-width: 528px;
  width: 528px;
  position: relative;
  padding: 24px;

  @media screen and (min-width: 768px) {
    padding: 32px 40px;
  }
}

.headPopup {
  display: flex;

  @media screen and (min-width: 768px) {
    margin-bottom: 32px;
    align-items: center;
    justify-content: space-between;
  }

  .popupTitle {
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    display: none;

    @media screen and (min-width: 768px) {
      display: block;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-direction: row-reverse;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 0 24px;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      gap: 8px;
      position: initial;
      bottom: initial;
      left: initial;
      transform: none;
      width: inherit;
      padding: 0;
    }

    button {
      width: 50%;
      justify-content: center;

      @media screen and (min-width: 768px) {
        width: inherit;
      }

      svg {
        display: none;

        @media screen and (min-width: 768px) {
          display: block;
        }
      }

      .cancel {
        @media screen and (min-width: 768px) {
          display: none;
        }
      }
    }
  }
}

.pictureForm {
  display: flex;
  align-items: center;
  gap: 32px;
  margin-bottom: 40px;
}

.pictureImg {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.file {
  cursor: not-allowed;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  svg {
    font-size: 18px;
    color: #000;
  }

  p {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
  }

  input {
    cursor: not-allowed;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    font-size: 0;
    z-index: 2;

    &::file-selector-button {
      font-size: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      margin: 0;
      padding: 0;
      font-size: 0;
      height: 100%;
      width: 100%;
      border: none;
      background-color: transparent;
    }
  }
}

.infoFormat {
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  color: var(--grey600);
  margin-bottom: 8px;
}

.infoWeight {
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  color: var(--grey600);
}

.inputFlex {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;

  div {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 32px;
    gap: 32px;
  }
}

.input {
  margin-bottom: 24px;

  @media screen and (min-width: 768px) {
    margin-bottom: 32px;
  }
}

.archive {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;

  @media screen and (min-width: 768px) {
    display: none;
  }

  button {
    font-size: 14px;
    font-weight: 700;
    display: flex;
    gap: 8px;
    align-items: center;

    svg {
      font-size: 17px;
    }
  }
}