.progress {
  position: absolute;
  top: 0;
  left: 80px;
  width: calc(100% - 80px);
  z-index: 2;
}

.wrapper {
  height: 100%;
  padding: 24px 0;
  position: relative;

  @media screen and (min-width: 1024px) {
    padding: 40px 0;
    height: 100vh;
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 64px;
    height: 64px;
  }
}

.header {
  display: flex;
  flex-direction: column;
  padding: 0 24px;

  @media screen and (min-width: 1024px) {
    // justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 0 40px;
  }
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 42px;
  color: #000000;

  @media screen and (min-width: 768px) {
    font-size: 32px;
  }
}

.pointsDone {
  margin-left: 0;
  display: flex;
  align-items: center;
  border: 2px solid var(--grey300);
  border-radius: 100px;
  padding: 10px 20px 10px 16px;
  width: 100%;
  justify-content: center;

  @media screen and (min-width: 1024px) {
    margin-left: auto;
    width: inherit;
  }

  svg {
    fill: #000;
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px - 42px);
}

.kanbanContainer {
  height: 93%;
  padding-top: 24px;

  @media screen and (min-width: 1024px) {
    height: calc(100vh - 80px - 82px);
    padding-top: 40px;
  }
}

.kanban {
  padding: 0 24px;
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 24px;
  height: inherit;
  overflow-x: auto;
  overflow-y: hidden;

  @media screen and (min-width: 1024px) {
    padding: 0 40px;
  }


  @media screen and (min-width: 1432px) {
    width: auto;
  }
}

.kanbanItem {
  position: relative;
  height: inherit;
  min-width: 300px;
  width: 25%;
}

.date {
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  color: var(--grey500);
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: flex-start;
  margin-top: 8px;

  svg {
    width: 18px;
    height: 18px;
    color: var(--grey500);
  }

  @media screen and (min-width: 1024px) {
    margin: 0 auto 10px 12px;
    align-self: flex-end;
  }
}

.dropdownContainer {
  position: relative;
  width: 100%
}

.dropdown {
  @media screen and (min-width: 1024px) {
    top: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    min-width: 161px;
    z-index: 10;
  }

}

.dropdownButton {
  svg {
    transform: rotate(90deg);
    width: 18px;
    height: 18px;
  }
}

.settings {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  min-height: 48px;
  padding: 0 16px;
  width: 50%;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  z-index: 2;
  background-color: var(--grey200);
  color: #000;
  flex-shrink: 0;

  @media screen and (min-width: 1024px) {
    width: 48px;
  }

  svg {
    fill: #000;
    width: 18px;
    height: 18px;
    font-size: 18px;
    color: #000;
    font-size: 18px;
  }

  &::after {
    background-color: var(--grey200);
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100px;
    transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    z-index: -1;
  }

  &:hover {
    background-color: var(--grey300);

    &::after {
      background-color: var(--grey300);
      transform: scale(1.1);
    }
  }

  span {
    @media screen and (min-width: 1024px) {
      display: none;
    }
  }
}

.filterButton {
  position: relative;
  width: 100%;

  button {
    &:first-child {

      width: 100%;
      justify-content: center;
    }
  }
}

.filterActive {
  width: 100%;
  justify-content: center;
  padding-right: 42px;
}

.resetFilter {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  display: flex;

  svg {
    width: 18px;
    height: 18px;
    fill: var(--grey400);

    &:hover {
      fill: var(--grey600);
    }
  }
}

.headWrapperMobile {
  display: flex;
  margin-top: 24px;
  gap: 16px;

  @media screen and (min-width: 1024px) {
    margin-top: 0;
  }
}


.moreButton {
  button {
    width: 100%;
    justify-content: center;
  }
}

.moreButton {
  width: 100%;

  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.menuSettings {
  display: none;
  position: fixed;
  bottom: 72px;
  left: 0px;
  z-index: 99;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 16px 16px 0px 0px;
  background: var(--gris-0, #FFF);

  @media screen and (min-width: 1024px) {
    margin-left: 16px;
    position: relative;
    display: flex;
    bottom: auto;
    width: inherit;
    flex-direction: row;
    background: none;
    border-radius: 0;
    padding: 0;
  }

  .wrapperMenuSettings {
    display: flex;
    gap: 16px;
    width: 100%;

    @media screen and (min-width: 1024px) {
      width: inherit;
    }
  }
}

.menuSettingsActive {
  @extend .menuSettings;
  display: flex;
}