.input {
    margin-bottom: 32px;
}

.dateButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
        font-size: 13px;
        font-weight: 700;
        line-height: 17px;
    }
}

.selectDate {
    display: flex;
    align-items: center;
    background-color: var(--grey200);
    padding-left: 16px;
    border-radius: 100px;
    svg {
        margin: 0 8px;
        width: 18px;
        height: 18px;
        color: #000;
    }
    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        padding: 12px 16px;
        border-radius: 100px;
    }
}

.endDate:hover,
.startDate:hover {
    background-color: var(--grey300);
}

.calendar {
    width: 648px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}