.container {
  position: relative;
}

.buttonContainer {
  display: flex;
  align-items: center;
  background-color: var(--grey200);
  border-radius: 100px;
  @media screen and (min-width: 500px) {
    width: fit-content;
  }
}

.button {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  padding-left: 12px;
  width: 100% !important;
  max-width: 100% !important;
  justify-content: space-between !important;
  @media screen and (min-width: 500px) {
    justify-content: center !important;
  }
  @media screen and (min-width: 768px) {
    font-size: 14px;
    padding: 0 8px 0 16px;
  }
  p {
    transition: background-color 0.1s ease-in;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    @media screen and (min-width: 768px) {
      font-weight: 600;
    }
  }
  svg {
    flex-shrink: 0;
  }
  .planningIcon {
    fill: #000;
    width: 18px;
    height: 18px;
    margin-right: 0px;
    @media screen and (min-width: 500px) {
      margin-right: 20px;
    }
  }
}

.noDate {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding: 0 8px 0 16px;
  transition: background-color 0.1s ease-in;
  border-radius: 100px;
  p {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .planningIcon {
    fill: #000;
    width: 18px;
    height: 18px;
    margin-right: 0px;
    @media screen and (min-width: 500px) {
      margin-right: 20px;
    }
  }
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100px;
    transition: 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    z-index: -1;
    background-color: var(--grey300);
  }
  &:hover {
    background-color: var(--grey300);
    &::after {
      transform: scale(1.1);
    }
  }
}

.arrow {
  width: 18px;
  height: 18px;
  margin: 0;
  @media screen and (min-width: 500px) {
    margin: 0 8px;
  }
}

.calendar {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  z-index: 10;
}

.resetFilters {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 100px;
  transition: background-color 0.1s ease-in;
  &:hover {
    background-color: var(--grey300);
  }
  svg {
    color: var(--grey600);
    width: 18px;
    height: 18px;
  }
}

.noDates {
  padding: 12px 16px 12px 0;
}

.startDate,
.endDate {
  padding: 12px 0px;
  border-radius: 100px;
  white-space: nowrap;
  font-size: 12px;
  @media screen and (min-width: 768px) {
    padding: 12px 16px;

    font-size: 14px;
  }
}

.endDate {
  background-color: var(--grey300);
  padding: 12px 16px;
  @media screen and (min-width: 768px) {
    background-color: transparent;
  }
}

.endDate:hover,
.startDate:hover {
  background-color: var(--grey300);
}
