.header {
    display: flex;
    height: 64px;
    padding: 0px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background-color: #fff;

    @media screen and (min-width: 1024px) {
        background-color: transparent;
        height: auto;
    }
}

.box {
    padding: 24px;
}

.title {
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
}

.close {
    margin-left: auto;
}

.customers {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin: 24px 0;

    li {
        button {
            padding: 12px 16px;
            border-radius: 100px;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
        }
    }
}

.missions {
    margin-top: 24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
}

.missionsList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
}