.wrapper {
  height: 100vh;
  width: 100vw;
  position: relative;
  padding: 32px;
  overflow-y: auto;
  background-color: #fff;
  @media screen and (min-width: 768px) {
    width: 528px;
    padding: 48px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  p {
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
  }
}
.buttons {
  display: flex;
  align-items: center;
  column-gap: 8px;
  :last-child {
    height: 42px;
    width: 42px;
  }
}

.input:not(:last-child) {
  margin-bottom: 32px;
}

.select {
  margin-top: 8px;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  padding: 12px 12px 12px 16px;
  background: var(--grey200);
  border-radius: 100px;
  justify-content: space-between;
  &__selector {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-indent: inherit;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: var(--grey900);
    padding: 12px 12px 12px 16px;
    cursor: pointer;
  }
  &__icon {
    margin-left: auto;
  }
}
