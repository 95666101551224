.container {
  display: flex;
  position: relative;
  max-width: 100%;
}

.containerData {
  &:hover::after {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  &::after {
    content: attr(data-text);
    position: absolute;
    z-index: 2;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translate(-50%, 10px);
    padding: 8px;
    background-color: #000;
    color: #fff;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease, transform 0.2s ease;
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
    border-radius: 8px;
  }
}

.tag {
  /* Add your desired styles for the tag here */
  display: inline-block;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  color: #000;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  color: #000;

  &:hover {
    .container::after {
      display: block;
    }
  }
}

.input {
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  color: #000;
  height: 17px;
  max-height: 17px;
  overflow: hidden;
  white-space: nowrap;
}

.input[placeholder]:empty::before {
  content: attr(placeholder);
  color: #000;
  opacity: 0.5;
}
